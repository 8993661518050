import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TogglerDirective } from './toggler.directive';

@NgModule({
    declarations: [
        TogglerDirective
    ],
    exports: [
        CommonModule,
        TogglerDirective
    ]
})
export class SharedModule { }
