import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }    from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { AppRoutesModule } from './app.routes.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomeModule } from './home/home.module';
import { APP_ROUTING } from './app.routes.module';
import { HeaderComponent } from './core/header/header.component';
import { CarouselComponent } from './utils/carousel/carousel.component';
import { SateliteComponent } from './pages/images/satelite/satelite.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { RouterModule } from '@angular/router';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { UavComponent } from './pages/images/uav/uav.component';
// import { ScannerComponent } from './pages/images/scanner/scanner.component';
// import { AereoComponent } from './pages/images/aereo/aereo.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    // FontAwesomeModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule.forRoot(),
    HomeModule,
    AppRoutesModule,
    APP_ROUTING,
    SharedModule,
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,    
    SateliteComponent,
    PrivacyPolicyComponent 
    // UavComponent, 
    // ScannerComponent, 
    // AereoComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
