import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as $ from "jquery";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = { title: 'TerraMapping' };

  constructor(private translate: TranslateService) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es/) ? browserLang : 'es');
  }

  public onChangeLanguage(lang: string): void {
    this.translate.use(lang);
  }

  public getLangs(): string[] {
    return this.translate.getLangs();
  }
}
