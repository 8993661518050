import { Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { NgModule } from '@angular/core';

import { HomeComponent } from './home/home.component';
import { SateliteComponent } from './pages/images/satelite/satelite.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
// import { UavComponent } from './pages/images/uav/uav.component';
// import { ScannerComponent } from './pages/images/scanner/scanner.component';
// import { AereoComponent } from './pages/images/aereo/aereo.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'satelite',
    component: SateliteComponent
  },
  {
    path: 'politicas',
    component: PrivacyPolicyComponent
  },
  // {
  //   path: 'uav',
  //   component: UavComponent
  // },
  // {
  //   path: 'scanner',
  //   component: ScannerComponent
  // },
  // {
  //   path: 'aereo',
  //   component: AereoComponent
  // },
  {
    path: '**',
    component: HomeComponent//PageNotFoundComponent
  }
];

export const APP_ROUTING = RouterModule.forRoot(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutesModule { }
     


    