import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
// import * as $ from "jquery";

declare const $;
@Component({
  selector: 'tm-satelite',
  templateUrl: './satelite.component.html',
  styleUrls: ['./satelite.component.scss']
})
export class SateliteComponent implements OnInit {
  isActive : boolean = true;
  isOptica : boolean = true;
  isRadar : boolean = true;
  constructor() {}
  
  ngOnInit() {
      this.all();
    }

  opticos(){
    $(".img_radar a").attr("data-gallery", "");
    $(".img_optica a").attr("data-gallery", "example-gallery");
    this.loadScript('myScript');
    this.loadScript('myScript2');
  }
  radar(){
    $(".img_optica a").attr("data-gallery", "");
    $(".img_radar a").attr("data-gallery", "example-gallery");
    this.loadScript('myScript');
    this.loadScript('myScript2');
  }
  all(){
    $(".img_optica a,.img_radar a").attr("data-gallery", "example-gallery");
    this.loadScript('myScript');
    this.loadScript('myScript2');
  }

  loadScript(url) {
    let node = document.createElement(url).src;
    document.getElementById(url).innerHTML=node;
  }

}
