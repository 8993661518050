import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HomeComponent } from './home.component';
import { HeaderComponent } from '../core/header/header.component';
import { CarouselComponent } from '../utils/carousel/carousel.component';
import { BackgroundImageDirective } from '../utils/directives/background-image/background-image.directive';
// import { AgmCoreModule } from '@agm/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
    //     AgmCoreModule.forRoot({
    //     apiKey: 'AIzaSyD8RYstfnKfEW8yMZWzc0LaPzaIxO1-eRM'
    //   })
    ],
    declarations: [
        HeaderComponent,
        CarouselComponent,
        HomeComponent,
        BackgroundImageDirective
    ]
})
export class HomeModule {
}
