import {
  Directive,
  HostListener,
  HostBinding
} from '@angular/core';

@Directive({
  selector: '.toggler'
})
export class TogglerDirective {

  @HostBinding('class.show')
  private isOpen: boolean;

  @HostListener('click')
  public toggle() {
    this.isOpen = !this.isOpen;
  }

  constructor() {
    this.isOpen = false;
  }
}
