import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
    selector: 'tm-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

    public isVisibleModal = 0;
     
    constructor(private modalService: NgbModal) { }
    ngOnInit() {
        /*
            Configuration main carousel
            Configuration staff carousel
        */
        this.loadScript('../assets/js/carousel.js');
        /*
            Email Validations
        */
        this.loadScript('../assets/js/email.js');
        /*
            Action Menu
        */
        this.loadScript('../assets/js/menu.js');

        //this.loadModal('modal',1);
    }

    loadScript(url) {
        let node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    loadModal(content,num) {
        this.isVisibleModal+=num;
        if(this.isVisibleModal <= 1){
            this.modalService.open(content).result.then((result) => {
                console.log(`Closed with: ${result}`);
            }, (reason) => {
                console.log(`Dismissed ${this.getDismissReason(reason)}`);
            });
        }        
    }

    open(content) {
        this.modalService.open(content).result.then((result) => {
            console.log(`Closed with: ${result}`);
        }, (reason) => {
            console.log(`Dismissed ${this.getDismissReason(reason)}`);
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
   
   
    lat: number = 20.701684;
    lng: number = -103.380831;
    zoom: number = 20;
    zoomControl: boolean = false;
    mapTypeControl: boolean = true;
    gestureHandling: string = 'auto';
    title: string = 'TERRADATA';
}
