import { Directive, OnInit, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[background-image]'
})
export class BackgroundImageDirective implements OnInit {

    private nel: any; // native element

    // tslint:disable-next-line no-input-rename
    @Input('background-image-src') url: string;

    @HostListener('mousemove', ['$event']) onMouseMove(evt) {
        this.nel.style.backgroundPosition = `${(evt.pageX * 1 / 250)}px`;
    }

    constructor(el: ElementRef) {
        this.nel = el.nativeElement;
    }

    public ngOnInit(): void {
        if (this.nel) {
            this.nel.style.backgroundImage = `url("${this.url}")`;
        }
    }
}
